import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import CloseButton from 'react-bootstrap/CloseButton';
import './VideoAd.css'; // Updated CSS file
import apiUrl from '../../../Config';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const VideoAd = () => {
    const videoRef = useRef(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [isMuted, setIsMuted] = useState(true);

    const toggleMute = () => {
      if (videoRef.current) {
        videoRef.current.muted = !isMuted;
        setIsMuted(!isMuted);
      }
    };
    
    useEffect(() => {
        const token = localStorage.getItem("authAToken");
        
        // Fetch the video URL after 20 seconds
        setTimeout(() => {
          axios.get(`${apiUrl}/api/admin-videos-ads/`)
          .then(response => {
            console.log(response.data[0]); 
            const ad = response.data[0]; 
            if (ad) {
              setVideoUrl(`${ad.ad_video}`);
              setShowVideo(true);
            }
          })
          .catch(error => {
            console.error('Error fetching the video ad:', error);
          });
        }, 10000); // 20 seconds delay
      }, []);
  
    const handleClose = () => {
      setVideoUrl(''); 
    };
  
    // Do not render the video ad if there's no video URL
    if (!videoUrl) return null;
  
    return (
      <div className="video-ad-container">
      {showVideo && (
        <div className="video-ad-content">
          <CloseButton onClick={handleClose} className="video-ad-close" />
          <div className="video-wrapper">
            <video className="video-ad" ref={videoRef} autoPlay muted={isMuted} loop>
              <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button onClick={toggleMute} className="mute-unmute-button">
              {isMuted ? (
                <VolumeOffIcon style={{ fontSize: 20, color: 'white' }} />
              ) : (
                <VolumeUpIcon style={{ fontSize: 20, color: 'white' }} />
              )}
            </button>
          </div>
        </div>
      )}
    </div>
    );
};

export default VideoAd;
