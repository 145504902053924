import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import { Container, Button, Form } from "react-bootstrap";
import apiUrl from "../../Config";
import "./style.css";
import case3im from "../../Assets/Picture.png";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import BaseMain from "../Base/Base";
import CityList from "../CityNameListAds.json";
import { Modal } from "react-bootstrap";
import Loading from "../Loading/Loading";
import ReactSwitch from "react-switch";
import Footer from '../Footer/Footer';

function CreateAd() {
  const [currentStep, setCurrentStep] = useState(1);
  const [user, setUser] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedSubCategory1, setSelectedSubCategory1] = useState("");
  const [selectedSubCategory2, setSelectedSubCategory2] = useState("");
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories1, setSubCategories1] = useState([]);
  const [subCategories2, setSubCategories2] = useState([]);
  const [files, setFiles] = useState([]);
  const [mobile, setMobile] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredCities, setFilteredCities] = useState([]);
  const [showInAllStates, setShowInAllStates] = useState(false); 

  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);

  
  const handlePincodeChange = (e) => {
    const value = e.target.value;
    setPincode(value);

    if (value.length === 6) {
     
      fetchLocationByPincode(value); 
    } else {
      setCity(""); 
      setState(""); 
    }
  };
  const fetchLocationByPincode = async (pincode) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      if (response.data[0].Status === "Success") {
        console.log(response.data)
        const postOffice = response.data[0].PostOffice[0];
        setCity(postOffice.District); // Set city
        setState(postOffice.State); // Set state
        setFieldErrors((prev) => ({ ...prev, pincode: "" })); // Clear error if any
      } else {
        setFieldErrors((prev) => ({ ...prev, pincode: "Invalid PIN code" }));
        setCity(""); // Clear city
        setState(""); // Clear state
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
      setFieldErrors((prev) => ({ ...prev, pincode: "Error fetching data" }));
      setCity(""); // Clear city
      setState(""); // Clear state
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (city) {
      const filtered = CityList.filter((c) =>
        c.toLowerCase().includes(city.toLowerCase())
      );
      setFilteredCities(filtered);
      setShowDropdown(filtered.length > 0);
    } else {
      setFilteredCities([]);
      setShowDropdown(false);
    }
  }, [city]);

  const handleSelectCity = (selectedCity) => {
    setCity(selectedCity);
    setShowDropdown(false);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      setSnackbarMessage("You need to log in to access this page.");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else {
      fetchMainCategories();
      setUser(localStorage.getItem("pk"));
    }
  }, [navigate]);

  const fetchMainCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/main-categories/`);
      console.log("Main Categories Response:", response.data); // Log the response data
      setMainCategories(response.data);
    } catch (error) {
      console.error("Error fetching main categories:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubCategories1 = async (mainCategory) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/main-categories/${mainCategory}/`
      );

      const subCategories = response.data.SubCategories || [];
      console.log(
        `Subcategories1 Response for ${mainCategory}:`,
        subCategories
      );
      setSubCategories1(subCategories);
      if (subCategories.length > 0) {
        setSelectedSubCategory1(subCategories[0]);
        fetchSubCategories2(mainCategory, subCategories[0].id);
      } else {
        setSelectedSubCategory1(null);
      }
    } catch (error) {
      console.error(
        `Error fetching subcategories1 for ${mainCategory}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchSubCategories2 = async (mainCategory, subCategory1) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/main-categories/${mainCategory}/${subCategory1}/`
      );
      // Log the full response data to verify the structure
      console.log(
        `Subcategories2 Response for ${mainCategory}/${subCategory1}:`,
        response.data
      );
      const subCategories = response.data.SubCategories || [];
      console.log(`Subcategories2 Data Extracted:`, subCategories);
      setSubCategories2(subCategories);
    } catch (error) {
      console.error(
        `Error fetching subcategories for ${mainCategory}/${subCategory1}:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("user", user);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("main_category", selectedMainCategory.value);
      formData.append("sub_category1", selectedSubCategory1.value);
      if (
        selectedSubCategory2 &&
        !subCategories2.some((cat) => cat.id === selectedSubCategory2.value)
      ) {
        throw new Error("Selected SubCategory2 ID does not exist.");
      }
      formData.append("show_in_all_states", showInAllStates);
      formData.append("mobile", mobile);
      formData.append("street", street);
      formData.append("city", city);
      formData.append("pincode", pincode);
      formData.append("state", state);
      files.forEach((file) => formData.append("files", file));
      for (var pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const authToken = localStorage.getItem("accessToken");
      console.log(authToken)

      const response = await axios.post(`${apiUrl}/api/free-ads/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("Ad created successfully:", response.data);
      setSnackbarMessage("Ad created successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      setTimeout(() => navigate("/Home"), 1000);
    } catch (error) {
      console.error("Error creating ad:", error);

      
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["jpeg", "jpg", "png", "webp"];
    const invalidFiles = Array.from(selectedFiles).filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return !allowedExtensions.includes(extension);
    });

    // Check if any files are invalid
    if (invalidFiles.length > 0) {
      setErrorMessage("Please upload only JPEG, JPG, PNG, or WEBP files.");
      event.target.value = ""; // Clear the input
      return;
    }


    if (selectedFiles.length > 0) {
      setErrorMessage(""); // Clear error message if images are selected
      const filesArray = Array.from(selectedFiles);

      // Resize images before saving them
      const resizedFilesPromises = filesArray.map((file) => resizeImage(file));
      Promise.all(resizedFilesPromises)
        .then((resizedFiles) => {
          const imagesWithUrls = resizedFiles.map((file, index) => ({
            file,
            url: URL.createObjectURL(file), 
            name: filesArray[index].name, 
          }));
          setSelectedImages(imagesWithUrls); 
          setFiles([...files, ...resizedFiles]); 
        })
        .catch((error) => {
          setErrorMessage("Error resizing images.");
          console.error(error);
        });
    }
  };
  const resizeImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const targetWidth = 350;
        const targetHeight = 250;

        // Calculate scaling factors
        const scaleX = targetWidth / img.width;
        const scaleY = targetHeight / img.height;
        const scale = Math.max(scaleX, scaleY); 
        // Calculate the new dimensions
        const newWidth = img.width * scale;
        const newHeight = img.height * scale;

        
        const offsetX = (targetWidth - newWidth) / 2;
        const offsetY = (targetHeight - newHeight) / 2;

        
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        
        ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], file.name, { type: file.type });
          resolve(resizedFile);
        }, file.type);
      };
    });
  };

  const removeImage = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  const openPreview = (imageUrl) => {
    setPreviewImage(imageUrl); 
  };

  const closePreview = () => {
    setPreviewImage(null); 
  };

  const handleChange = (e) => {
    const { name, value } = e.target || e;

    switch (name) {
      case "title":
        setTitle(value);
        setFieldErrors({
          ...fieldErrors,
          title:
            value.trim().length > 6
              ? ""
              : "Title must contain more than 6 characters.",
        });
        break;
      case "description":
        setDescription(value);
        setFieldErrors({
          ...fieldErrors,
          description:
            value.trim().length > 20
              ? ""
              : "The description must contain more than 20 characters.",
        });
        break;
      case "mainCategory":
        setSelectedMainCategory(value);
        setFieldErrors({
          ...fieldErrors,
          mainCategory: value ? "" : "Please select a main category.",
        });
        break;
      case "subCategory1": // Handling for subCategory1
        setSelectedSubCategory1(value);
        setFieldErrors({
          ...fieldErrors,
          subCategory1: value ? "" : "Please select a subcategory.",
        });
        break;
      case "subCategory2": // Handling for subCategory1
        setSelectedSubCategory2(value);
        setFieldErrors({
          ...fieldErrors,
          subCategory2: value ? "" : "Please select a subcategory.",
        });
        break;
      case "mobile":
        setMobile(value);
        setFieldErrors({
          ...fieldErrors,
          mobile:
            value.trim().length >= 10
              ? ""
              : "Please enter a valid mobile number.",
        });
        break;
      case "street":
        setStreet(value);

        setFieldErrors({
          ...fieldErrors,
          street: value.trim().length > 0 ? "" : "Please enter your Address.",
        });
        break;
      case "city":
        setCity(value);
        setFieldErrors({
          ...fieldErrors,
          city: value.trim().length > 0 ? "" : "Please enter your city.",
        });
        break;
      case "pincode":
        setPincode(value);
        setFieldErrors({
          ...fieldErrors,
          pincode: value.trim().length > 0 ? "" : "Please enter your pincode.",
        });
        break;
      case "state":
        setState(value);
        setFieldErrors({
          ...fieldErrors,
          state: value.trim().length > 0 ? "" : "Please enter your state.",
        });
        break;
      default:
        break;
    }
  };

  const nextStep = () => {
    let errors = {};

    if (currentStep === 1) {
      if (title.trim().length <= 6) {
        errors.title = "Title must contain more than 6 characters.";
      }
      if (description.trim().length <= 20) {
        errors.description =
          "The description must contain more than 20 characters.";
      }
    } else if (currentStep === 2 && !selectedMainCategory) {
      errors.mainCategory = "Please select a main category.";
    } else if (currentStep === 3) {
      if (subCategories1.length < 0 && !selectedSubCategory1) {
        errors.subCategory1 = "Please select a subcategory.";
      }
    } else if (currentStep === 4) {
      if (subCategories2.length === 0 || !selectedSubCategory1) {
        setCurrentStep(currentStep + 1);
        return;
      }
    } else if (currentStep === 5) {
      if (currentStep === 5) {
        if (files.length === 0) {
          setErrorMessage("Please upload at least one file.");
          return;
        }
        if (files.length > 5) {
          setErrorMessage("You can upload up to 5 files.");
          return;
        }
      }
    } else if (currentStep === 6) {
      if (!mobile) {
        errors.mobile = "Please enter your mobile number.";
      }
      if (!street) {
        errors.street = "Please enter your address.";
      }
      if (!city) {
        errors.city = "Please enter your city.";
      }
      if (!pincode) {
        errors.pincode = "Please enter your pincode.";
      }
      if (!state) {
        errors.state = "Please enter your state.";
      }
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    setCurrentStep(currentStep + 1);
    setFieldErrors({});
  };

  const validateMobile = (value) => {
    
    const isValid = /^\d{10}$/.test(value);
    if (!isValid) {
      setFieldErrors((prev) => ({
        ...prev,
        mobile: "Please enter a valid 10-digit mobile number.",
      }));
    } else {
      setFieldErrors((prev) => ({ ...prev, mobile: "" })); 
    }
    return isValid;
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);

    validateMobile(value);
  };

  const prevStep = () => {
    if (currentStep === 5) {
      setCurrentStep(3);
    } else {
      setCurrentStep(currentStep - 1);
    }
    setErrorMessage("");
  };


  const handleSubCategory1Change = (selectedOption) => {
    setSelectedSubCategory1(selectedOption);
    fetchSubCategories2(selectedMainCategory.value, selectedOption.value); 
  };

  const handleSubCategory2Change = (selectedOption) => {
    setSelectedSubCategory2(selectedOption);
  };

  const handleMainCategoryChange = (selectedOption) => {
    setSelectedMainCategory(selectedOption);
    fetchSubCategories1(selectedOption.value);
  };
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Container className="case1-container">
            <h3 className="h3-case1">Enter Your Title & Description</h3>

            <Form.Group controlId="title">
              <Form.Control
                className={`title-form ${
                  fieldErrors.title ? "error-border" : ""
                }`}
                type="text"
                value={title}
                onChange={handleChange}
                name="title"
                placeholder="Title"
              />
              <div className="Error-text">
                <div className="Error-text2">
                  {fieldErrors.title && (
                    <div className="error-message">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon"
                      />
                      {fieldErrors.title}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="description" style={{ marginTop: "20px" }}>
              <Form.Control
                className={`descr-form ${
                  fieldErrors.description ? "error-border" : ""
                }`}
                as="textarea"
                rows={6}
                value={description}
                onChange={handleChange}
                name="description"
                placeholder="Type your description..."
                style={{ width: "100%" }}
              />
              <div className="Error-text">
                <div className="Error-text2">
                  {fieldErrors.description && (
                    <div className="error-message">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon"
                      />
                      {fieldErrors.description}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
            <div className="case1-button-container ">
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );
      case 2:
        return (
          <Container className="case2-container">
            <h3 className="h3-case2">Select Main Category</h3>

            <Form.Group controlId="mainCategory">
              <Select
                className="mainCategory-form"
                value={selectedMainCategory}
                onChange={handleMainCategoryChange}
                options={mainCategories.map((category) => ({
                  value: category.id,
                  label: category.MainCategory,
                }))}
              />
              <div className="Error-text">
                <div className="Error-text2">
                  {fieldErrors.mainCategory && !selectedMainCategory && (
                    <div className="error-message">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon"
                      />
                      {fieldErrors.mainCategory}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>

            <div className="button-container">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );
      case 3:
        return (
          <Container className="case2-container">
            <h3 className="h3-case2">Select Sub Category</h3>

            {selectedMainCategory && (
              <Form.Group controlId="subCategory1">
                <Select
                  className="mainCategory-form"
                  value={selectedSubCategory1}
                  onChange={handleSubCategory1Change}
                  options={subCategories1.map((category) => ({
                    value: category.id,
                    label: category.SubCategory,
                  }))}
                  isDisabled={!selectedMainCategory}
                />
                <div className="Error-text">
                  <div className="Error-text2">
                    {fieldErrors.subCategory1 && (
                      <div className="error-message">
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          className="error-icon"
                        />
                        {fieldErrors.subCategory1}
                      </div>
                    )}
                  </div>
                </div>
              </Form.Group>
            )}

            <div className="button-container">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );

      case 4:
        if (subCategories2.length === 0) {
          nextStep();
          return null;
        }
        return (
          <Container className="case2-container">
            <h3 className="h3-case2">Select Sub Category || </h3>

            {selectedSubCategory1 && subCategories2.length > 0 && (
              <Form.Group controlId="subCategory2">
                <Select
                  value={selectedSubCategory2}
                  onChange={(selectedOption) =>
                    setSelectedSubCategory2(selectedOption)
                  }
                  options={subCategories2.map((category) => ({
                    value: category.id,
                    label: category.SubCategory,
                  }))}
                />
              </Form.Group>
            )}

            <div className="button-container">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );
      case 5:
        return (
          <Container className="case3-container">
            <h3 className="h3-case1">Upload Images</h3>
            <div className="dot_rec" onClick={handleImageClick}>
            <img src={case3im} alt="" className="dotrecimg" />
              <div className="droptext">
                <div className="droptext1">Drop your image here, or</div>
                <div className="droptext2"> browse</div>
              </div>
              <div className="droptext3">Supports: PNG, JPG, JPEG, WEBP</div>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>

            {errorMessage && (
              <div className="Error-text">
                <div className="Error-text2">
                  <div className="error-message">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="error-icon"
                    />
                    {errorMessage}
                  </div>
                </div>
              </div>
            )}

            {selectedImages.length > 0 && (
              <div className="selected-images-container">
                <p className="selected-count">
                  {selectedImages.length} image
                  {selectedImages.length > 1 ? "s" : ""} selected
                </p>
                <ul className="selected-images-list">
                  {selectedImages.map(
                    (
                      imageObj,
                      index 
                    ) => (
                      <li key={index} className="selected-image-item">
                        <img
                          src={imageObj.url} 
                          className="preview-image" 
                          onClick={() => openPreview(imageObj.url)} 
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                          }}
                        />
                        <button
                          className="remove-button"
                          onClick={() => removeImage(index)}
                        >
                          Remove
                        </button>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            <div className="button-container">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>

            {/* Modal for Image Preview */}
            <Modal show={previewImage !== null} onHide={closePreview}>
              <Modal.Header closeButton>
                <Modal.Title>Image Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {previewImage && (
                  <img
                    src={previewImage}
                    alt="Preview"
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
              </Modal.Body>
            </Modal>
          </Container>
        );
      case 6:
        return (
          <Container className="case4-container">
            <h3 className="h3-case4">Address Details</h3>

            <Form.Group controlId="mobile">
              <Form.Control
                className={`mobile-form ${
                  fieldErrors.mobile ? "error-border" : ""
                }`}
                type="text"
                value={mobile}
                onChange={handleMobileChange}
                placeholder="Mobile"
                maxLength={10} // Limit the input length to 10 characters
              />
              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.mobile && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.mobile}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="street">
              <Form.Control
                className={`street-form ${
                  fieldErrors.street ? "error-border" : ""
                }`}
                type="text"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                placeholder="Address"
              />
              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.street && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.street}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="pincode">
              <Form.Control
                className={`pincode-form ${
                  fieldErrors.pincode ? "error-border" : ""
                }`}
                type="text"
                value={pincode}
                // onChange={(e) => setPincode(e.target.value)}
                onChange={handlePincodeChange}
                placeholder="Pincode"
              />
              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.pincode && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.pincode}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Control
                className={`city-form ${
                  fieldErrors.city ? "error-border" : ""
                }`}
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="City"
                onBlur={() => setTimeout(() => setShowDropdown(false), 100)}
                onFocus={() => setShowDropdown(true)} // Show dropdown on focus
              />
              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.city && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.city}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>

            <Form.Group controlId="state">
              <Form.Control
                className={`state-form ${
                  fieldErrors.state ? "error-border" : ""
                }`}
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="State"
              />

              <div className="Error-text4">
                <div className="Error-text24">
                  {fieldErrors.state && (
                    <div className="error-message4">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="error-icon4"
                      />
                      {fieldErrors.state}
                    </div>
                  )}
                </div>
              </div>
            </Form.Group>

            <div className="button-container ">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>
              <button className="case1button" onClick={nextStep}>
                <span className="next">Next</span>
              </button>
            </div>
          </Container>
        );
      case 7:
        return (
          <Container className="review-container">
            <h3 className="h7-header">Review Details & Sumbit</h3>

            <div className="review-section">
              <div>
                <div className="review-carousel-container">
                  <Carousel className="review-files-ca">
                    {Array.from(files).map((file, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="reviewfile-preview-image"
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
                <p>
                  <strong>Title:</strong> {title}
                </p>
                <p>
                  <strong>Description:</strong> {description}
                </p>

                <p>
                  <strong>Main Category:</strong> {selectedMainCategory.label}
                </p>
                <p>
                  <strong>Subcategory:</strong> {selectedSubCategory1.label}
                </p>
                {selectedSubCategory2 && (
                  <p>
                    <strong>Subcategory 2:</strong> {selectedSubCategory2.label}
                  </p>
                )}

                <p>
                  <strong>Mobile:</strong> {mobile}
                </p>
                <p>
                  <strong>Address:</strong> {street}
                </p>
                <p>
                  <strong>City:</strong> {city}
                </p>
                <p>
                  <strong>Pincode:</strong> {pincode}
                </p>
                <p className="state-container">
                  <strong>State:</strong> {state}
                  
                </p>
                <div className="switch-container">
  <Form.Group className="d-flex justify-content-between align-items-center">
    <span className="toggle-label">
      Do you want to show your post in all cities?
    </span>
    <ReactSwitch
      checked={showInAllStates}
      onChange={(checked) => setShowInAllStates(checked)}
      onColor="#4CAF50"  
      offColor="#FF6347" 
      handleDiameter={20} 
      uncheckedIcon={false} 
      checkedIcon={false}  
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" 
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" 
      height={24} 
      width={46}  
      className="ms-2"
    />
  </Form.Group>
</div>
              </div>
            </div>

            <div className="button-container">
              <button className="secondarybutton" onClick={prevStep}>
                <span className="previous">Previous</span>
              </button>

              <Button className="sub-ad-cnfrm case1button" type="submit">
                Submit
              </Button>
            </div>
          </Container>
        );

      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "270px" }}>
      {loading && <Loading />}
      <Container className="ads-container">
        <BaseMain />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <MuiAlert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <Form onSubmit={handleSubmit}>{renderStep()}</Form>
      </Container>
      <div className="createad-footer"> <Footer /> </div>
      
    </div>
  );
}

export default CreateAd;
