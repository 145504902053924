import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import apiUrl from "../../Config";

import "../Base/BaseM.css";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../Assets/letter-k.png";
import "../Base/BaseM.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Container, Navbar, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import TopMessageBar from "../KAdmin/TopMessageBar/TopMessageBar.js";
import DefaultProfilePhoto from "../../Assets/placeholder.jpg";
import Select from 'react-select';

function BaseMain({ onSearch }) {
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories1, setSubCategories1] = useState({});
  const [subCategories2, setSubCategories2] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [selectedCity, setSelectedCity] = useState(null);
  const [cities, setCities] = useState([]);
  const [query, setQuery] = useState("");
  const [ads, setAds] = useState([]);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [isMessageBarVisible, setIsMessageBarVisible] = useState(true);

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const categoryScrollRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);

  const handleSearch = () => {
    if (searchQuery.trim() === "") return;
    navigate(`/search?query=${searchQuery}`);
  };

  const fetchCities = async () => {
    try {
      const response = await import("../CityNameList.json"); // Import the JSON
      const cityData = response.default || response;

      // Map the array of city names (strings) to the required format
      setCities(
        cityData.map((city) => ({
          label: city, // For display in the dropdown
          value: city, // For selection value
        }))
      );
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };



  const fetchMainCategories = async () => {
    try {
      const url = `${apiUrl}/api/main-categories/`;
      console.log("Fetching from URL:", url);

      const response = await axios.get(url);

      setMainCategories(response.data);
    } catch (error) {
      console.error("Error fetching main categories:", error);
      setError(error);
    }
  };

  const fetchSubCategories1 = useCallback(
    async (mainCategoryId) => {
      if (!subCategories1[mainCategoryId]) {
        try {
          const response = await axios.get(
            `${apiUrl}/api/main-categories/${mainCategoryId}/`
          );
          setSubCategories1((prev) => ({
            ...prev,
            [mainCategoryId]: response.data.SubCategories,
          }));
        } catch (error) {
          console.error(
            `Error fetching subcategories1 for ${mainCategoryId}:`,
            error
          );
          setError(error);
        }
      }
    },
    [subCategories1]
  );

  // Fetch subcategories level 2 based on subcategory level 1
  const fetchSubCategories2 = async (mainCategoryId, subCategory1Id) => {
    if (!subCategories2[subCategory1Id]) {
      try {
        const response = await axios.get(
          `${apiUrl}/api/main-categories/${mainCategoryId}/subcategories2/`
        );
        setSubCategories2((prev) => ({
          ...prev,
          [subCategory1Id]: response.data,
        }));
      } catch (error) {
        console.error(
          `Error fetching subcategories2 for ${subCategory1Id}:`,
          error
        );
        setError(error);
      }
    }
  };

  const handleMainCategoryOnClick = async (mainCategoryId, event) => {
    const element = event.currentTarget;
    const { top, left, height } = element.getBoundingClientRect();

    const dropdownTop = top + window.scrollY + height;
    const dropdownLeft = left + window.scrollX;

    setDropdownPosition({ top: dropdownTop, left: dropdownLeft });

    setSelectedMainCategory(mainCategoryId);
    fetchSubCategories1(mainCategoryId);
  };

  const handleSubCategory1Click = (subCategory1Id, subCategory) => {
    fetchSubCategories2(selectedMainCategory, subCategory1Id);
    localStorage.setItem("selectedMainCategory", selectedMainCategory);
    console.log(
      `Navigating to URL: /posts/${selectedMainCategory}/${subCategory1Id}/${encodeURIComponent(
        subCategory
      )}`
    );
    navigate(
      `/posts/${selectedMainCategory}/${subCategory1Id}/${encodeURIComponent(
        subCategory
      )}`
    );
    setSelectedMainCategory(null);
  };
  const handleScrollLeft = () => {
    if (categoryScrollRef.current) {
      categoryScrollRef.current.scrollBy({
        left: -200, // Scrolls to the left by 200px
        behavior: 'smooth', // Smooth scrolling
      });
    }
  };
  
  const handleScrollRight = () => {
    if (categoryScrollRef.current) {
      categoryScrollRef.current.scrollBy({
        left: 200, // Scrolls to the right by 200px
        behavior: 'smooth', // Smooth scrolling
      });
    }
  };

  useEffect(() => {
    // This will ensure the scroll starts at the first category
    if (categoryScrollRef.current) {
      categoryScrollRef.current.scrollLeft = 0;
    }

    // Optionally, add an event listener to reset scroll on window resize
    const handleResize = () => {
      if (categoryScrollRef.current) {
        categoryScrollRef.current.scrollLeft = 0;
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseLeaveDropdown = () => {
    setSelectedMainCategory(null);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSelectedMainCategory(null);
    }
  };

  useEffect(() => {
    fetchMainCategories();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    fetchCities();

    const storedCity = localStorage.getItem("selectedCity");
    if (storedCity) {
      setSelectedCity({ label: storedCity, value: storedCity }); // Set the saved city as selected
    }
  }, []);

  useEffect(() => {
    // Fetch subcategories when a main category is selected
    if (selectedMainCategory) {
      fetchSubCategories1(selectedMainCategory);
    }
  }, [selectedMainCategory]);

  useEffect(() => {
    const fetchData = async () => {
      if (accessToken) {
        try {
          setLoading(true);
          const cachedUserDetails = JSON.parse(
            localStorage.getItem("userDetails")
          );
          if (cachedUserDetails) {
            setUserDetails(cachedUserDetails);
          } else {
            const userId = localStorage.getItem("pk");
            if (userId) {
              const response = await axios.get(
                `${apiUrl}/api/userdetail/${userId}/`,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );

              if (response.status === 200) {
                setUserDetails(response.data);
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(response.data)
                );
              } else {
                console.error(
                  "Failed to fetch user details. Status code:",
                  response.status
                );
              }
            } else {
              console.error("userId is null");
            }
          }
        } catch (error) {
          console.error("Error during fetching user details:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [accessToken]);

  let username = userDetails ? userDetails.username : "...";
  username = username.charAt(0).toUpperCase() + username.slice(1);
  const handleTopMessageBarClose = () => {
    setIsMessageBarVisible(false);
    
  };

  const handleCityChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedCity(selectedOption); // Set the selected city in state
      localStorage.setItem("selectedCity", selectedOption.value); // Save the city to localStorage
      console.log(`Selected city: ${selectedOption.label}`);
    } else {
      setSelectedCity(null);
      localStorage.removeItem("selectedCity"); // Remove the city from localStorage
      console.log("City selection cleared");
    }
  };

  const storedCity = localStorage.getItem("selectedCity");
  return (
    <>
      {isMessageBarVisible && (
        <TopMessageBar onClose={handleTopMessageBarClose} />
      )}
      <Navbar
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top custom-navbar"
        style={{
          marginTop: isMessageBarVisible ? "20px" : "0px", 
          transition: "margin-top 0.3s ease",
        }}
      >
        <Navbar.Brand href="/Home">
          <Image src={Logo} alt="Logo" width="40" height="40" />
        </Navbar.Brand>

        <InputGroup className="rectangle-8-57B customfont">
  <FormControl
    style={{
      fontFamily: "Montserrat, sans-serif",
      height: '36px', // Set the fixed height for FormControl
      lineHeight: '36px', // Align text vertically
    }}
    placeholder="I am looking for..."
    aria-label="Search"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === "Enter") {
        handleSearch();
      }
    }}
  />
  
  <div className="Selectcity" style={{ position: 'relative', width: '150px', marginLeft: '0px' }}>
  <Select
    options={cities}
    onChange={(selectedOption) => {
      handleCityChange(selectedOption); // Call your city change handler
      setSearchQuery(selectedOption?.label || ''); // Update the search query
    }}
    value={selectedCity}
    placeholder={selectedCity ? selectedCity.label : 'Select City'}
    onKeyDown={(e) => {
      if (e.key === "Enter") {
        handleSearch();
      }
    }}
    isClearable
    isSearchable
    styles={{
      container: (provided) => ({
        ...provided,
        width: '100%',
        position: 'relative', // Make the container relative to position the dropdown inside it
      }),
      control: (provided) => ({
        ...provided,
        borderRadius: '0', // Remove border radius
        minHeight: '36px', // Set fixed height
        height: '36px', // Set fixed height
        fontFamily: 'Montserrat, sans-serif', // Font styling
        border: '1px solid #ccc', // Light border for consistency
        boxShadow: 'none', // Remove shadow
        backgroundColor: '#fff', // White background
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#aaa',
      }),
      placeholder: (provided) => ({
        ...provided,
        fontFamily: 'Montserrat, sans-serif',
        color: '#aaa',
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 10000, // Ensure it's higher than .cat-scroll-wrapper
      position: 'absolute', // Ensure the dropdown is positioned correctly
      top: 'calc(100% + 2px)', // Ensures the dropdown is on top of other elements
      }),
      menuList: (provided) => ({
        ...provided,
        padding: 0, // Remove extra padding around the list
      }),
    }}
  />
</div>


<Button 
    className="button-s" 
    style={{
      height: '36px', // Match the height
      minHeight: '36px', // Ensure min height
    // Align text vertically in the button
    }} 
    onClick={handleSearch}
  >
    <FontAwesomeIcon icon={faSearch} className="icon-s" />
  </Button>
</InputGroup>


        {accessToken ? (
          <Container className="loginprofile">
            <div className="main-details a-r flex-container">
              <Link to="/userdetails" className="profile-link">
                <img
                  src={
                    userDetails && userDetails.profile_photo
                      ? `${apiUrl}${userDetails.profile_photo}?${new Date().getTime()}`
                      : DefaultProfilePhoto
                  }
                  alt="Profile"
                  className="rounded-circle profile-image"
                />
              </Link>
              <Container className="words">
                <div className="hi-guest-login-hPT">
                  <p className="newusername">
                    <Link to="/userdetails" className="user-na">
                      {username}
                    </Link>
                  </p>
                  <p className="create-ad-zYu">
                    <Link to="/CreateAd" className="cu-bm">
                      Create Ad!
                    </Link>
                  </p>
                </div>
              </Container>
            </div>
          </Container>
        ) : (
          <Container className="withoutlogin">
            <div className="g-s-k">
              <p className="hi-guest-login-hPT">
                <span className="hi-guest-login-hPT-sub-0">Hi Guest |</span>
                <span className="hi-guest-login-hPT-sub-1">
                  <Link className="cu-fo-col" to="/Login">
                    Login
                  </Link>
                </span>
              </p>
              <div>
                <p className="create-ad-zYu">
                  <Link className="cu-fo-col" to="/CreateAd">
                    Create Ad!
                  </Link>
                </p>
              </div>
            </div>
          </Container>
        )}
      </Navbar>

      <div
        className="cat-scroll-wrapper"
        style={{
          marginTop: isMessageBarVisible ? "20px" : "0px",
          transition: "margin-top 0.3s ease",
        }}
      >
        <div className="three-wide-column">
          <Icon name="angle left" size="big" onClick={handleScrollLeft} />
        </div>
        <div className="cat-scroll" ref={categoryScrollRef}>
          <ul className="category-list">
            {Array.isArray(mainCategories) &&
              mainCategories.map((category) => (
                <li
                  key={category.id}
                  // onMouseEnter={(event) =>
                  //   handleMainCategoryOnClick(category.id, event)
                  // }
                  onClick={(event) =>
                    handleMainCategoryOnClick(category.id, event)
                  }
                  className="category-item"
                >
                  {category.MainCategory}
                  
                </li>
              ))}
          </ul>
        </div>

        <div className="three-wide-column">
          <Icon name="angle right" size="big" onClick={handleScrollRight} />
        </div>
      </div>

      {selectedMainCategory &&
        Array.isArray(subCategories1[selectedMainCategory]) &&
        subCategories1[selectedMainCategory].length > 0 && (
          <div
            ref={dropdownRef}
            className="subcategories-dropdown"
            style={{
              top: `${dropdownPosition.top}px`,
              left: `${dropdownPosition.left}px`,
              width: `${dropdownPosition.width}px`,
              position: "absolute",
              zIndex: 1000,
            }}
            onMouseLeave={handleMouseLeaveDropdown}
          >
            <ul>
              {subCategories1[selectedMainCategory].map((subcat1) => (
                <li
                  key={subcat1.id}
                  onClick={() =>
                    handleSubCategory1Click(subcat1.id, subcat1.SubCategory)
                  }
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {subcat1.SubCategory}
                  <span style={{ color: "blue", marginLeft: "auto" }}>
                    {subcat1.post_count}
                  </span>
                  {Array.isArray(subCategories2[subcat1.id]) && (
                    <ul className="subcategories2-list">
                      {subCategories2[subcat1.id].map((subcat2) => (
                        <li
                          key={subcat2.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {subcat2.SubCategory}
                          <span style={{ color: "blue" }}>
                            {subcat2.post_count} posts
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
    </>
  );
}

export default BaseMain;
