import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './TopMessageBar.css'; // Import your CSS file
import apiUrl from '../../../Config';
import CloseButton from 'react-bootstrap/CloseButton';

const TopMessageBar = ({ onClose }) => {
  // State to hold the ad data and custom styles
  const [ad, setAd] = useState({ text: '', url: '' });
  const [customStyles, setCustomStyles] = useState({ bgColor: '', textColor: '', font: '', fontSize: '' });

  // Fetch the custom styles
  const getCustomStyles = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/custom-styles/`);
  
      if (response.data) {
        const customStyleData = response.data;
        setCustomStyles({
          bgColor: customStyleData.bg_color || '#ffffff',
          textColor: customStyleData.text_color || '#000000',
          font: customStyleData.font_style || 'Arial, sans-serif', 
          fontSize: customStyleData.font_size || '16px',
        });
      } else {
        console.error('No style data found in the response');
        
        setCustomStyles({
          bgColor: '#ffffff',
          textColor: '#000000',
          font: 'Arial, sans-serif',
          fontSize: '16px',
        });
      }
    } catch (error) {
      console.error('Error fetching custom styles:', error);
      // Set default styles in case of an error
      setCustomStyles({
        bgColor: '#ffffff',
        textColor: '#000000',
        font: 'Arial, sans-serif',
        fontSize: '16px',
      });
    }
  };
  

  // Function to fetch top ads
  const getTopAds = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/admin-top-ads/`);
      console.log(response);
      const adData = response.data[0];
      if (adData) {
        setAd({
          text: adData.ad_text || '',
          url: adData.url || '',
        });
      }
    } catch (error) {
      console.error('Error fetching the top ad:', error);
    }
  };

  useEffect(() => {
    getCustomStyles();
    getTopAds();
  }, []); 



  const handleClose = () => {
    setAd({ text: '', url: '' });
    onClose();

  };

  if (!ad.text) return null;

  return (
    <div
      className="top-message-bar"
      style={{
        backgroundColor: customStyles.bgColor || '#ffffff', // Default to white
        color: customStyles.textColor || '#000000',         // Default to black
        fontFamily: customStyles.font || 'Arial, sans-serif', // Default font family
        fontSize: customStyles.fontSize || '16px',          // Default font size
      }}
      
    >
      <a
  href={ad.url} 
  className="ad-link " 
  target="_blank" 
  rel="noopener noreferrer" 
  style={{ color: customStyles.textColor }}
>
  {ad.text}
</a>

  
  <span onClick={handleClose} className="adclose" style={{ color: customStyles.textColor }} >
        <CloseButton />
      </span>
    </div>
  );
};

export default TopMessageBar;
