import React, { useEffect, useState } from "react";
import axios from "axios";
import { Carousel } from "react-bootstrap"; // Import Carousel component
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import apiUrl from "../../../Config"; // Adjust the path as necessary

const HomeImgAd = () => {
  const [homePageAds, setHomePageAds] = useState([]);

  useEffect(() => {
    const fetchHomePageAds = async () => {
      try {
        const token = localStorage.getItem("authAToken");
        const response = await axios.get(`${apiUrl}/api/admin-home-page-ads/`);
        console.log(response.data);

        setHomePageAds(response.data);
      } catch (error) {
        console.error(
          "There was an error fetching the HomePageAd data!",
          error
        );
      }
    };

    fetchHomePageAds();
  }, []);

  const firstAd = homePageAds.length > 0 ? homePageAds[0] : null;

  return (
    <>
      <div className="homepage-ads-container">
        {firstAd?.ad_photos?.length > 0 ? (
          <Carousel>
            {firstAd.ad_photos.map((photo) => (
              <Carousel.Item key={photo.id}>
                <img
                  src={photo.ad_file}
                  alt="Ad"
                  className="d-block w-100 adimg-photo"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <p>No images available for this ad.</p>
        )}
      </div>
    </>
  );
};

export default HomeImgAd;
