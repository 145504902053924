import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import apiUrl from "../../Config.js";
import "./Home.css";
import { Container, Spinner, Card, Carousel } from "react-bootstrap";
import Footer from "../Footer/Footer";
import BaseMain from "../Base/Base";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";
import TopMessageBar from "../KAdmin/TopMessageBar/TopMessageBar.js";
import VideoAd from "../KAdmin/VideoAd/VideoAd.js";
import HomeImgAd from "../KAdmin/HomeImgAd/HomeImgAd.js";

function Home() {
  const [ip, setIp] = useState(null);
  const [geoData, setGeoData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const [searchResults, setSearchResults] = useState([]);
  const categoryScrollRef = useRef(null);
  const [topBarVisible, setTopBarVisible] = useState(true);
  const [location, setLocation] = useState(null);
  const [city, setCity] = useState(false);

  // Fetch user's IP address
  // const getCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setLocation({ latitude, longitude });
  //         console.log("User's Location:", { latitude, longitude });
  //       },
  //       (error) => {
  //         console.error("Error getting location:", error);
  //         setError("Unable to retrieve your location.");
  //       }
  //     );
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //     setError("Geolocation not supported.");
  //   }
  // };

  const fetchIpAddress = async () => {
    try {
      console.log("Fetching IP address...");
      const response = await axios.get(`${apiUrl}/api/api_ip/get_ip/`);

      const { ip, city } = response.data; // Extracting ip and city from response
      console.log("IP Address:", ip); // Print the IP address
      console.log("City:", city); // Print the city

      const ipsavedcity = localStorage.setItem("city", city);
      console.log(ipsavedcity);
      setIp(ip);
      setCity(city);
    } catch (error) {
      console.error("Error fetching IP:", error);
      setError(error);
    }
  };

  const fetchGeoLocation = async () => {
    if (ip) {
      try {
        console.log(`Fetching geolocation for IP: ${ip}`);
        const response = await axios.get(`${apiUrl}/api/geolocation/${ip}/`);
        console.log("Geolocation Response:", response.data); // Debug: Log response
        setGeoData(response.data);
      } catch (error) {
        console.error("Error fetching geolocation:", error);
        setError(error);
      }
    } else {
      console.log("No IP address found.");
    }
  };

  const handleCloseTopBar = () => {
    setTopBarVisible(false);
  };

  useEffect(() => {
    fetchIpAddress();
    // getCurrentLocation();
  }, []);

  useEffect(() => {
    fetchGeoLocation();
  }, [ip]);

  const handleSearch = async (query) => {
    setLoading(true);
    setError(null);
    setSearchQuery(query);
    try {
      const response = await axios.get(`${apiUrl}/api/ads/search/`, {
        params: { query },
      });
      setSearchResults(response.data);
    } catch (err) {
      console.error("Error fetching ads:", err);
      setError("An error occurred while fetching the ads.");
    }
    setLoading(false);
  };

  return (
    <>
      <Container className="home-base">
        <BaseMain onSearch={handleSearch} />

        <div className="homecontentcontainer">
          <div className="hoepa">
            <div class="homepage-content">
              <header class="welcome-banner">
                <h1>
                  Welcome to <span>Klassifies.com</span> – Your Ultimate
                  Klassifieds Hub!
                </h1>
                <p>
                  Discover a world of opportunities with Klassifies.com, your
                  go-to platform for buying, selling, and connecting in your
                  local community. Whether you’re looking to find the perfect
                  item, explore new services, or connect with others nearby,
                  Klassifies.com makes it easy and convenient.
                </p>
                <a href="#get-started" class="cta-button">
                  Get Started Today
                </a>
              </header>

              {/* <section class="why-choose-us">
                <h2>Why Choose Us?</h2>
                <div class="features">
                  <div class="feature-item">
                    <i class="icon free-icon"></i>
                    <h3>Free to Use</h3>
                    <p>Post your ads without spending a dime.</p>
                  </div>
                  <div class="feature-item">
                    <i class="icon categories-icon"></i>
                    <h3>Wide Range of Categories</h3>
                    <p>
                      From vehicles and real estate to jobs and services, we
                      cover it all.
                    </p>
                  </div>
                  <div class="feature-item">
                    <i class="icon user-friendly-icon"></i>
                    <h3>User-Friendly Platform</h3>
                    <p>
                      Find what you need or create an ad in just a few clicks.
                    </p>
                  </div>
                  <div class="feature-item">
                    <i class="icon global-reach-icon"></i>
                    <h3>Local and Global Reach</h3>
                    <p>
                      Connect with buyers and sellers near you or expand your
                      reach globally.
                    </p>
                  </div>
                </div>
              </section> */}

              <section class="get-started" id="get-started">
                <h2>Get Started Today!</h2>
                <div class="actions">
                  <div class="action-item">
                    <i class="icon browse-icon"></i>
                    <h3>Browse Listings</h3>
                    <p>
                      Explore our extensive range of categories and find exactly
                      what you’re looking for.
                    </p>
                  </div>
                  <div class="action-item">
                    <i class="icon post-ad-icon"></i>
                    <h3>Post an Ad</h3>
                    <p>
                      Create a free listing for items you want to sell or
                      services you offer.
                    </p>
                  </div>
                  <div class="action-item">
                    <i class="icon connect-icon"></i>
                    <h3>Connect and Communicate</h3>
                    <p>
                      Use our messaging system to securely communicate with
                      buyers or sellers.
                    </p>
                  </div>
                </div>
                <a href="/listings" class="cta-button">
                  Start Exploring
                </a>
              </section>

              <footer class="community-invite">
                <p>
                  Join our growing community today and discover a smarter,
                  simpler way to connect.
                  <strong>
                    Start exploring or posting your ads now – it's completely
                    free!
                  </strong>
                </p>
              </footer>
            </div>
          </div>
          <div className="adbox">
            <HomeImgAd />
          </div>
        </div>

        <VideoAd />
      </Container>
      <div className="career-footer">
        <Footer />
      </div>
    </>
  );
}

export default Home;
