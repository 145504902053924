import React, { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../Config";
import {
  Container,
  Modal,
  Button,
  Carousel,
  Pagination,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer/Footer";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import "./update.css";
import Base from "../Base/Base";
import Loading from "../Loading/Loading";
import Snackbar from "@mui/material/Snackbar";
import defaultProfilePhoto from "../../Assets/placeholder.jpg";

function UserDetailsComponent(props) {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [editing, setEditing] = useState(false);
  const [userAds, setUserAds] = useState([]);
  const [selectedAds, setSelectedAds] = useState([]);
  const [expandedAd, setExpandedAd] = useState(null);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAdDetailsModal, setShowAdDetailsModal] = useState(false);
  const [selectedAdDetails, setSelectedAdDetails] = useState(null);
  const [isEditingAd, setIsEditingAd] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(""); // GREEN: Add success message state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchUserAds = async (page) => {
    try {
      setLoading(true); // Show loading screen
      setUserAds([]);
      const userId = localStorage.getItem("pk");
      const authToken = localStorage.getItem("accessToken");
      console.log(authToken);
      if (!userId || !authToken) {
        console.error("userId or authToken is missing or null");
        setLoading(false);
        return;
      }

      const url = `${apiUrl}/api/adsuser/${userId}/?page=${page}`;
      console.log(url)
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: "application/json",
        },
      });

      if (response.status === 200 && Array.isArray(response.data.results)) {
        setUserAds(response.data.results);
        setNextUrl(response.data.next);
        setPreviousUrl(response.data.previous);
        setTotalPages(Math.ceil(response.data.count / 10));
        setCurrentPage(page);
        console.log("Current page:", page);
        console.log("Current page:", response.data.results);

      } else {
        console.error(
          "Failed to fetch user ads. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error during fetching user ads:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log('currentPage:', currentPage);
  //   fetchUserAds(currentPage);
  // }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage !== currentPage && newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("pk");
      const authToken = localStorage.getItem("accessToken");

      if (userId && authToken) {
        const url = `${apiUrl}/api/userdetail/${userId}/`;
        console.log(url)
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
          },
        });

        if (response.status === 200) {
          console.log("user detials",response.data);
          setUserDetails(response.data);
          localStorage.setItem("userDetails", JSON.stringify(response.data));
        } else {
          console.error(
            "Failed to fetch user details. Status code:",
            response.status
          );
        }
      } else {
        console.error("userId or authToken is null");
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("useEffect triggered with currentPage:", currentPage);
    fetchData();
    fetchUserAds(currentPage);  
  }, [currentPage]);

  useEffect(() => {
    if (userDetails) {
      setUsername(userDetails.username);
    }
  }, [userDetails]);

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const calculateRemainingTimeForHe = (ad) => {
    if (!ad.expiry_date) {
      return <span>No Expiry Date</span>;
    }

    const isExpired = moment().isAfter(moment(ad.expiry_date));
    return (
      <span className={isExpired ? "expired" : "active"}>
        {isExpired ? "Expired" : "Active"}
      </span>
    );
  };

  const calculateRemainingTime = () => {
    if (!selectedAdDetails.expiry_date) {
      return "Not specified";
    }

    const expiryDate = moment(selectedAdDetails.expiry_date);
    const currentDate = moment();

    if (currentDate.isBefore(expiryDate)) {
      const remainingDuration = moment.duration(expiryDate.diff(currentDate));
      const days = remainingDuration.days();
      const hours = remainingDuration.hours();
      const minutes = remainingDuration.minutes();
      const seconds = remainingDuration.seconds();

      if (days > 0) {
        return `${days} days`;
      } else if (hours > 0) {
        return `${hours} hours`;
      } else if (minutes > 0) {
        return `${minutes} minutes`;
      } else {
        return `${seconds} seconds`;
      }
    } else {
      return "Expired";
    }
  };

  const handleFileChange = (e) => {
    setProfilePhoto(e.target.files[0]);
  };

  const handleSave = async () => {
    try {
      const userId = localStorage.getItem("pk");
      const authToken = localStorage.getItem("accessToken");

      if (userId && authToken) {
        const formData = new FormData();
        formData.append("username", username);
        formData.append("profilePhoto", profilePhoto);

        const response = await axios.put(
          `${apiUrl}/api/userdetail/${userId}/`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setShowEditModal(false);
          setUserDetails(response.data);
          const updatedUserDetails = JSON.parse(
            localStorage.getItem("userDetails")
          );
          console.log(
            "Updated userDetails in localStorage: ",
            updatedUserDetails
          );
        } else {
          console.error(
            "Failed to update user details. Status code:",
            response.status
          );
        }
      } else {
        console.error("userId or authToken is null");
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const handleCheckboxChange = (adId) => {
    const isSelected = selectedAds.includes(adId);
    if (isSelected) {
      setSelectedAds(selectedAds.filter((id) => id !== adId));
    } else {
      setSelectedAds([...selectedAds, adId]);
    }
  };

  const clearExpandedAd = () => {
    setExpandedAd(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".ad-item") === null) {
        clearExpandedAd();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem("userDetails");
    localStorage.removeItem("pk");
    localStorage.removeItem("accessToken");
    setShowLogoutModal(false);
    toast.success("You have successfully logged out.");
    navigate("/Home");
  };

  const cancelLogout = () => {
    setShowLogoutModal(false);
  };

  const handleAdClick = (ad) => {
    setSelectedAdDetails(ad);
    setShowAdDetailsModal(true);
  };

  const handleEditAd = () => {
    setIsEditingAd(true);
  };

  const handleSaveAd = async () => {
    try {
      const userId = localStorage.getItem("pk");
      const authToken = localStorage.getItem("accessToken");

      if (selectedAdDetails && userId && authToken) {
        const apiEdit = `${apiUrl}/ads/${userId}/${selectedAdDetails.id}/`; // Construct the URL with both user ID and ad ID
        console.log("PUT URL:", apiEdit);

        const response = await axios.put(apiEdit, selectedAdDetails, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          setIsEditingAd(false);
          setSelectedAdDetails(response.data);
          const updatedAds = userAds.map((ad) =>
            ad.id === response.data.id ? response.data : ad
          );
          setUserAds(updatedAds);
          setShowAdDetailsModal(false);
        } else {
          console.error(
            "Failed to update ad details. Status code:",
            response.status
          );
        }
      } else {
        console.error("adDetails or userId or authToken is null");
      }
    } catch (error) {
      console.error("Error during updating ad details:", error);
    }
  };

  const handleAdDetailsChange = (e) => {
    const { name, value } = e.target;
    setSelectedAdDetails({
      ...selectedAdDetails,
      [name]: value,
    });
  };

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  const determineAdContainerSize = () => {
    const adCount = userAds.length;
    if (adCount <= 5) {
      return "extra-small";
    } else if (adCount <= 10) {
      return "small";
    } else if (adCount <= 15) {
      return "medium";
    } else if (adCount <= 20) {
      return "large";
    } else {
      return "extra-large";
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmModal(true);
  };

  const confirmDelete = () => {
    handleDeleteAd();
    setShowDeleteConfirmModal(false);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmModal(false);
  };

  const handleDeleteAd = async () => {
    try {
      const userId = localStorage.getItem("pk");
      const authToken = localStorage.getItem("accessToken");

      if (!authToken) {
        console.error("authToken is missing");
        return;
      }

      // Send PUT request to update the 'hide_on_delete' field
      const response = await axios.put(
        `${apiUrl}/api/ads/${userId}/${selectedAdDetails.id}/`,
        { hide_on_delete: true },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log(`Ad ${selectedAdDetails.id} deleted`);
        setShowAdDetailsModal(false);

        setUserAds((prevAds) =>
          prevAds.filter((ad) => selectedAdDetails.id !== selectedAdDetails.id)
        );

        setSuccessMessage("Ad successfully deleted");
        setOpenSnackbar(true);
      } else {
        console.error(
          "Failed to mark the ad as hidden. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error marking ad as hidden:", error);
    }
  };


  return (
    <>
      <Container className="full-us-container">
        <Base />
        <div style={{ marginTop: "170px" }}></div>

        <Container className="wholecontainer">
          <Container className="userSide-container">
            {userDetails ? (
              <div className="profile-wrapper">
                <div className="profile-container">
                <img
                  src={
                    userDetails && userDetails.profile_photo
                      ? `${apiUrl}${userDetails.profile_photo}?${new Date().getTime()}`
                      :  defaultProfilePhoto
                  }
                  alt="Profile"
                  className="rounded-circle us-pro"
                />

                  <div className="edit-icon" onClick={handleEdit}>
                    <i className="fas fa-pen"></i>
                  </div>
                </div>
                <div className="us-dis">
                  <p>@{username}</p>
                </div>
              </div>
            ) : (
              <p>No user details available</p>
            )}
            <a className="logout-button" onClick={handleLogout}>
              <i className="fas fa-sign-out-alt"></i>
              <span className="us-detail-logout">Logout</span>
            </a>
          </Container>
          <Container className="ad-container">
            <h6 className="ad-name">Your Ads</h6>
            {loading ? (
              <Loading />
            ) : Array.isArray(userAds) && userAds.length > 0 ? (
              userAds.map((ad) => (
                <div key={ad.id} className="ad-item-container">
                  <div className="ad-item">
                    <div
                      className="ad-content"
                      onClick={() => handleAdClick(ad)}
                    >
                      <p className="title-ex-p">{ad.title}</p>
                      <p className="description-ex-p">
                        {truncateText(ad.description, 100)}
                      </p>
                    </div>
                    <div className="usdetails-de">
                      <div className="createat-p">
                        <p>{moment(ad.created).fromNow()}</p>
                      </div>
                      <span
                        className={`adver-color ${
                          ad.is_verified ? "verified-status" : "pending-status"
                        }`}
                      >
                        {ad.is_verified ? "Verified" : "Pending verification"}
                        <span className="comma">,</span>{" "}
                        {calculateRemainingTimeForHe(ad)}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No ads available</p>
            )}
          </Container>

          <Modal show={showLogoutModal} onHide={cancelLogout}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Logout</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to logout?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={cancelLogout}>
                Cancel
              </Button>
              <Button variant="danger" onClick={confirmLogout}>
                Logout
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input type="file" accept="image/*" onChange={handleFileChange} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEditModal(false)}
              >
                Back
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showAdDetailsModal}
            onHide={() => setShowAdDetailsModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Ad Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedAdDetails && !isEditingAd ? (
                <div>
                  {selectedAdDetails && selectedAdDetails.files && (
                    <Carousel className="image-carousel">
                      {selectedAdDetails.files.map((file, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100"
                            src={`${apiUrl}${file.file}`}
                            alt={`Image ${index + 1}`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  )}
                  <h3>{selectedAdDetails.title}</h3>
                  <p>{selectedAdDetails.description}</p>
                  <p>Category: {selectedAdDetails.main_category}</p>
                  <p>Sub Category: {selectedAdDetails.sub_category1}</p>
                  <p>Mobile: {selectedAdDetails.mobile}</p>
                  <p>Street: {selectedAdDetails.street}</p>
                  <p>City: {selectedAdDetails.city}</p>
                  <p>Pincode: {selectedAdDetails.pincode}</p>
                  <p>State: {selectedAdDetails.state}</p>

                  <p>
                    Created on:{" "}
                    {moment(selectedAdDetails.created_at).format("LLL")}
                  </p>
                  <p>
                    Is Verified:{" "}
                    {selectedAdDetails.is_verified
                      ? "Verified"
                      : "Not Verified"}
                  </p>
                  <p>Expires in: {calculateRemainingTime()}</p>

                  <Button
                    variant="danger"
                    className="delete_button"
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </Button>
                </div>
              ) : (
                selectedAdDetails && (
                  <div className="edit-modal-container">
                    <input
                      type="text"
                      name="title"
                      value={selectedAdDetails.title}
                      onChange={handleAdDetailsChange}
                    />
                    <textarea
                      name="description"
                      value={selectedAdDetails.description}
                      onChange={handleAdDetailsChange}
                    />
                    <input
                      type="text"
                      name="mobile"
                      value={selectedAdDetails.mobile}
                      onChange={handleAdDetailsChange}
                    />
                    <input
                      type="text"
                      name="street"
                      value={selectedAdDetails.street}
                      onChange={handleAdDetailsChange}
                    />
                    <input
                      type="text"
                      name="city"
                      value={selectedAdDetails.city}
                      onChange={handleAdDetailsChange}
                    />
                    <input
                      type="text"
                      name="pincode"
                      value={selectedAdDetails.pincode}
                      onChange={handleAdDetailsChange}
                    />
                    <input
                      type="text"
                      name="state"
                      value={selectedAdDetails.state}
                      onChange={handleAdDetailsChange}
                    />

                    <div className="button-container">
                      <button
                        className="secondarybutton"
                        variant="secondary"
                        onClick={() => setIsEditingAd(false)}
                      >
                        <span className="Cancel">Previous</span>
                      </button>
                      <button
                        className="save_button"
                        variant="primary"
                        onClick={handleSaveAd}
                      >
                        <span>Save</span>
                      </button>
                    </div>
                  </div>
                )
              )}
            </Modal.Body>
          </Modal>
          <Modal
            show={showDeleteConfirmModal}
            onHide={cancelDelete}
            dialogClassName="modal-dialog-centered custom-modal-centered"
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this ad?</p>
              <Button variant="secondary" onClick={cancelDelete}>
                Cancel
              </Button>
              <Button variant="danger" onClick={confirmDelete}>
                Confirm
              </Button>
            </Modal.Body>
          </Modal>

          <ToastContainer />
        </Container>
        <div className="pagination">
          <Pagination>
            {previousUrl && (
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
              />
            )}
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            {nextUrl && (
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
              />
            )}
          </Pagination>
        </div>
        <div userside-footer>
          {" "}
          <Footer />
        </div>
      </Container>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={successMessage}
      />
    </>
  );
}

export default UserDetailsComponent;
